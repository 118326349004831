import React from "react";
import theme from "theme";
import { Theme, Image, Text, Button, Box, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Школа англійської Evergreen
			</title>
			<meta name={"description"} content={"Школа англійської Evergreen - професійні заняття з англійської мови для всіх рівнів. Поліпшіть свої знання англійської з нами."} />
			<meta property={"og:title"} content={"Школа англійської Evergreen"} />
			<meta property={"og:description"} content={"Школа англійської Evergreen - професійні заняття з англійської мови для всіх рівнів. Поліпшіть свої знання англійської з нами."} />
			<meta property={"og:image"} content={"https://vorix-caita.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vorix-caita.com/img/325356456.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vorix-caita.com/img/325356456.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vorix-caita.com/img/325356456.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vorix-caita.com/img/325356456.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vorix-caita.com/img/325356456.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vorix-caita.com/img/325356456.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="10px 0 40px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Image
				src="https://vorix-caita.com/img/1.jpg"
				display="block"
				height="450px"
				object-fit="cover"
				border-radius="35px"
				border="2px solid #eecd6c"
				md-height="400px"
				sm-height="300px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="40px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-googleDancingScript"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					Школа англійської Evergreen
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 400 24px/1.3 --fontFamily-sansHelvetica"
					color="#50555a"
					padding="0px 50px 0px 50px"
					text-align="center"
					lg-padding="0px 0 0px 0"
					md-font="normal 400 22px/1.3 --fontFamily-sansHelvetica"
				>
					Ми раді вітати вас у нашій школі англійської мови, де ми допомагаємо студентам досягати нових висот у вивченні англійської. Наші професійні викладачі та ефективні методики навчання забезпечують високий рівень підготовки для студентів усіх рівнів.
				</Text>
				<Button
					margin="0px 0 20px 0px"
					padding="14px 35px 14px 35px"
					background="rgba(238, 205, 108, 0.7)"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="rgba(238, 205, 108, 0.7)"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					type="link"
					text-decoration-line="initial"
					target="_blank"
					href="/contact"
					color="--dark"
				>
					Зв'яжіться з нами
				</Button>
			</Box>
		</Section>
		<Section padding="0 0 0 0" quarkly-title="Team-18">
			<Override slot="SectionContent" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				flex-direction="column"
				align-items="flex-end"
				border-width="0 0 2px 2px"
				border-style="solid"
				border-color="rgba(238, 205, 108, 0.7)"
			>
				<Text
					margin="20px 0px 0 250px"
					font="normal 900 52px/1.2 --fontFamily-serifGaramond"
					text-align="right"
					padding="0px 0px 30px 0px"
					lg-margin="20px 0px 0 0"
					md-font="normal 900 45px/1.2 --fontFamily-serifGaramond"
				>
					Наші переваги
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				margin="0px 0px 0 0px"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
					border-width="0 2px 0 0"
					border-style="solid"
					border-color="rgba(238, 205, 108, 0.7)"
					padding="0px 50px 0px 0px"
					lg-padding="0px 25px 0px 0px"
					md-border-width="0 0 2px 2px"
					md-padding="0px 25px 25px 0px"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://vorix-caita.com/img/2.jpg"
							display="block"
							width="100%"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 22px --fontFamily-serifGaramond" lg-margin="10px 0px 0px 0px" margin="10px 0px 0 10px">
						Індивідуальний підхід
						</Text>
						<Text
							margin="15px 0px 0px 0px"
							font="300 22px/140% --fontFamily-sansHelvetica"
							color="--darkL2"
							sm-margin="18px 0px 0px 0px"
							padding="0px 0px 0px 15px"
						>
							Ми створюємо індивідуальні навчальні плани для кожного студента, враховуючи їхні потреби та цілі.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
					border-width="0 2px 0 0"
					border-style="solid"
					border-color="rgba(238, 205, 108, 0.7)"
					padding="120px 50px 0px 0px"
					lg-padding="120px 25px 0px 0px"
					md-border-width="0 0 2px 2px"
					md-padding="0 25px 25px 0px"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://vorix-caita.com/img/3.jpg"
							display="block"
							width="100%"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 22px --fontFamily-serifGaramond" lg-margin="10px 0px 0px 0px" margin="10px 0px 0 10px">
						Висококваліфіковані викладачі
						</Text>
						<Text
							margin="15px 0px 0px 0px"
							font="300 22px/140% --fontFamily-sansHelvetica"
							color="--darkL2"
							sm-margin="18px 0px 0px 0px"
							padding="0px 0px 0px 15px"
						>
							Наші викладачі мають багаторічний досвід і використовують сучасні методики навчання.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
					padding="240px 50px 80px 0px"
					lg-padding="240px 25px 80px 0px"
					md-border-width="0 0 0 2px"
					md-border-style="solid"
					md-border-color="rgba(238, 205, 108, 0.7)"
					md-padding="0 25px 80px 0px"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://vorix-caita.com/img/4.jpg"
							display="block"
							width="100%"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 22px --fontFamily-serifGaramond" lg-margin="10px 0px 0px 0px" margin="10px 0px 0 10px">
						Комфортні умови навчання
						</Text>
						<Text
							margin="15px 0px 0px 0px"
							font="300 22px/140% --fontFamily-sansHelvetica"
							color="--darkL2"
							sm-margin="18px 0px 0px 0px"
							padding="0px 0px 0px 15px"
						>
							Наші класи оснащені всім необхідним для комфортного і ефективного навчання.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="rgba(238, 205, 108, 0.7)"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Приєднуйтесь до нас вже сьогодні!
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					Не втрачайте можливість покращити свої знання англійської мови зі школою англійської Evergreen. Наші курси допоможуть вам досягти ваших мовних цілей.
				</Text>
				<Link
					href="/contact"
					color="--lightD1"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-red"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
					target="_blank"
				>
					Записатися зараз
				</Link>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://vorix-caita.com/img/5.jpg"
					object-fit="cover"
					max-height="600px"
					width="100%"
					object-position="0% 0%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});